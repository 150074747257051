<template>
  <div class="checkbox-with-label">
    <input
      type="checkbox"
      :id="id"
      :class="['checkbox', inputClass]"
      :disabled="disabled"
      :checked="checked"
      @input="$emit('input', $event.target.checked)"
    >
    <label :for="id" :class="['label', labelClass]">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxWithLabel',
  props: {
    id: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/base/global_vars';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/components/_checkboxes';
@import '~theme/css/layout/_margin';

$color-silver: color(silver);
$color-active: color(partner);
$color-white: color(white);
$color-gray: color(gray);
$color-partner: color(partner);
$color-matterhorn: color(matterhorn);

.checkbox {
  position: absolute;
  top: 3px;
  left: 0;
  opacity: 0;

  &:checked + .label {
    &:before {
      background-color: $color-partner;
      border-color: $color-partner;
      cursor: pointer;
    }

    &:after {
      content: '';
      position: absolute;
      left: 5px;
      width: 11px;
      height: 22px;
      border: 3px solid $color-white;
      border-top: none;
      border-right: none;
      transform: rotateY(180deg) rotate(-45deg) scale(0.6) translate(10%, -15%);
    }
  }

  &:disabled + .label {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;

    &:before {
      background-color: $color-gray;
      border: 2px solid $color-gray;
    }

    &:hover,
    &:focus {
      &:before {
        cursor: not-allowed;
      }
    }
  }
}

.label {
  user-select: none;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: $color-white;
    border: 2px solid $color-silver;
    cursor: pointer;
  }
}
</style>
