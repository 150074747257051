












































































































































































































































































































































import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import ButtonFull from 'theme/components/theme/ButtonFull.vue';
import Aselect from 'theme/components/theme/anyrent/aselect/Aselect.vue';
import ToggleArray from 'theme/components/theme/anyrent/helpers/ToggleArray';
import BaseInput from 'theme/components/core/blocks/Form/BaseInput.vue';
import debounce from 'lodash-es/debounce'
import LoaderOverlay from 'theme/components/core/LoaderOverlay.vue';
import config from 'config'
import HeaderDropDown from 'theme/components/theme/HeaderDropDown.vue';
import { InputType } from '../biometricDataTypes';
import BaseCheckbox from '../../Form/BaseCheckbox.vue';
import { transformStaticPageQueryResponse } from './OrderReview.vue';
import { ModalType } from 'src/constants/ModalTypeToSlugMapping';
import STATIC_PAGE_QUERY from 'src/themes/default/graphql/strapi/queries/staticPage.gql';
import StaticPageModalContent from 'theme/components/theme/anyrent/Static/Partners/StaticPageModalContent.vue'
import Modal from 'theme/components/core/Modal.vue'
import { Logger } from '@vue-storefront/core/lib/logger';
import CheckboxWithLabel from 'theme/components/theme/CheckboxWithLabel.vue';

export default {
  name: 'AdditionalServices',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    BaseInput,
    ButtonFull,
    Aselect,
    LoaderOverlay,
    HeaderDropDown,
    BaseCheckbox,
    Modal,
    StaticPageModalContent,
    CheckboxWithLabel
  },
  data () {
    return {
      isFilled: false,
      activeServices: [],
      openedProductsDropdown: new ToggleArray(),
      activeServicesIdList: [],
      priceDetails: 'Kč vč. DPH',
      InputType,
      modalContent: [], // To store the fetched content
      modalTitle: '', // To store the title of the modal
      modalType: '', // To store the type of the modal
      ModalType, // Exposing the ModalType Enum for template usage
      modalLoading: false, // To track the modal loading state
      biometricDataAgreement: false,
      areServicesChanging: false,
      loaderVisible: false

    };
  },
  computed: {
    isSubmitBtnDisabled () {
      // Disable the button if processing, biometric agreement not accepted, or no products
      return this.isProcessing || (this.biometricModels.length > 0 && !this.biometricDataAgreement) || !this.products;
    },
    isProcessing () {
      return this.areServicesChanging || this.loaderVisible
    },
    storeView () {
      return currentStoreView();
    },
    products () {
      return this.$store.getters['additionalServices/getProducts'];
    },
    userNote: {
      get () {
        return this.$store.getters['checkout/getUserNote'];
      },
      set (note) {
        this.$store.dispatch('checkout/setUserNote', note);
      }
    },
    biometricModels: {
      get () {
        try {
          const oldBiometricModels = this.getOldBiometricModels();
          const newlyAddedProducts = this.getNewlyAddedProducts(oldBiometricModels);
          const initializedBiometricModels = this.initializeBiometricModels(newlyAddedProducts);

          return initializedBiometricModels.concat(oldBiometricModels);
        } catch (error) {
          console.error('Error in biometricModels computed property:', error);
          return [];
        }
      },
      set (data) {
        this.$store.dispatch('additionalServices/setBiometricData', data); // Dispatch to the additionalServices store
      }
    }
  },
  beforeMount () {
    this.$bus.$on('order-after-placed', this.orderAfterPlaced)
    this.$bus.$on('loader-overlay-change', this.onLoaderVisibilityChange);
  },
  beforeDestroy () {
    this.$bus.$off('order-after-placed', this.orderAfterPlaced)
    this.$bus.$off('loader-overlay-change', this.onLoaderVisibilityChange);
  },
  methods: {
    getOldBiometricModels () {
      return this.$store.getters['additionalServices/getBiometricData'] || [];
    },

    getNewlyAddedProducts (oldBiometricModels) {
      return (this.products || [])
        .filter(product => product.items?.some(item => item.biometricData)) // Filter products with biometricData
        .filter(product => !oldBiometricModels.find(model => model.productId === product.id)); // Exclude products already in old models
    },

    initializeBiometricModels (newlyAddedProducts) {
      return newlyAddedProducts.map(product => this.createBiometricModelByProduct(product)).filter(Boolean);
    },

    createBiometricModelByProduct (product) {
      try {
        return {
          productId: product.id,
          productName: product.name || '',
          models: this.extractBiometricModels(product)
        };
      } catch (error) {
        console.error(`Error processing product ID ${product?.id}:`, error);
        return null; // Skip invalid product
      }
    },

    extractBiometricModels (product) {
      return (product.items || [])
        .filter(item => item.biometricData) // Only include items with biometricData
        .map(item =>
          (item.biometricData || []).map(biometricItem => ({
            itemId: item.id,
            id: biometricItem.id,
            label: biometricItem.name || '',
            code: biometricItem.code || '',
            type: biometricItem.type || '',
            placeholder: biometricItem.type === InputType.SELECT
              ? { id: '0', name: `Vyberte ${biometricItem.name?.toLowerCase()}` }
              : `Vyplňte ${biometricItem.name?.toLowerCase()}`,
            value: ''
          }))
        );
    },
    onLoaderVisibilityChange (isVisible) {
      this.loaderVisible = isVisible;
    },
    orderAfterPlaced () {
      // Clear out old biometric data
      this.biometricModels = []
    },
    updateBiometricData (productId, biometricItemId, newValue) {
      // Find the product and update its biometric data
      const updatedBiometricData = this.biometricModels.map(product => {
        if (product.productId !== productId) {
          return product;
        }
        return {
          ...product,
          models: product.models.map(itemModels =>
            itemModels.map(biometricItem => {
              if (biometricItem.id !== biometricItemId) {
                return biometricItem;
              }
              return { ...biometricItem, value: newValue }; // Update the value
            })
          )
        };
      });
      this.biometricModels = updatedBiometricData; // This triggers the setter
    },
    getBiometricModelPlaceholder (productId, biometricItemId) {
      const product = this.biometricModels.find(p => p.productId === productId);
      if (product) {
        for (const model of product.models) {
          const biometricItem = model.find(item => item.id === biometricItemId);
          if (biometricItem) {
            return biometricItem.placeholder; // Return the placeholder value
          }
        }
      }
      return ''; // Return an empty string if no placeholder is found
    },
    getBiometricModelValue (productId, biometricItemId) {
      const product = this.biometricModels.find(p => p.productId === productId);
      if (product) {
        for (const model of product.models) {
          const biometricItem = model.find(item => item.id === biometricItemId);
          if (biometricItem) {
            return biometricItem.value; // Return the current value
          }
        }
      }
      return {};
    },
    displayPartnerData () {
      return config.partnerData.displayPartnerPages;
    },
    updateService: debounce(function () {
      const servicesToUpdate = this.products.flatMap(product => {
        return product.items.map(item => {
          return item.services.filter(service => service.selected)
        })
      }).flat()
      if (servicesToUpdate) {
        this.$store.dispatch('additionalServices/setActive', servicesToUpdate)
      }
      this.areServicesChanging = false;
    }, 1500),
    shouldDisplayHeaderDropDown (item) {
      return this.isActive || (this.getSelectedServiceOptions(item).length && !this.isActive);
    },
    renderServiceOptionInOverview (product, service) {
      if ((!this.openedProductsDropdown.hasItem(`${product.id}_${service.item_id}`) || !this.isActive) && service.selected_option) {
        return ' - ' + service.selected_option.name;
      }
      return '';
    },
    getSelectedServiceOptions (item) {
      return item.services.filter(service => service.selected);
    },
    handleServiceSelect (service) {
      this.areServicesChanging = true;
      service.selected = !service.selected;
      this.updateService()
    },
    handleOptionSelect (service, option) {
      this.areServicesChanging = true;
      if (!service.selected) {
        service.selected = true;
      }
      service.selected_option = option;
      this.updateService()
    },
    getPossibleAdditionalServices () {
      const possibleServices = [];
      if (this.products) {
        for (const product of this.products) {
          for (const item of product.items) {
            if (item?.services) {
              for (const service of item.services) {
                possibleServices.push(service);
              }
            }
          }
        }
      }
      return possibleServices;
    },
    sendDataToCheckout () {
      this.openedProductsDropdown.setArray([])
      this.firstProduct = undefined;
      this.isFilled = true;
      this.$bus.$emit('checkout-after-additionalServices');
    },
    edit () {
      this.$bus.$emit('checkout-before-edit', 'additionalServices');
    },
    async fetchModalContentAndOpenModal (type: ModalType) {
      const slug = this.getSlugForType(type);
      // Validate that the type is valid and has a corresponding slug
      if (!slug) {
        console.error(`Invalid modal type provided: ${type}`);
        return;
      }

      this.modalLoading = true;
      this.modalType = type;
      try {
        const partnerId = config.partnerData.id;
        const response = await this.$apollo.query({
          query: STATIC_PAGE_QUERY,
          variables: {
            filters: {
              Slug: { eq: slug },
              Partner: { Name: { eq: partnerId } }
            }
          }
        });

        if (!response.data.staticPages.data.length) {
          Logger.error('No content found for slug:', slug);
          return;
        }

        const staticPage = transformStaticPageQueryResponse(response);
        this.modalContent = staticPage.pageContent;
        this.modalTitle = staticPage.title;

        this.modalLoading = false;
        this.$bus.$emit('modal-toggle', `modal-${type}`);
      } catch (error) {
        this.modalLoading = false;
        Logger.error('Failed to fetch modal content:', error);
      }
    },
    getSlugForType (modalType: ModalType): string {
      return config.partnerData.modalTypeToSlugMapping[modalType] || '';
    }
  },
  watch: {
    products () {
      if (this.openedProductsDropdown.getArray()?.length) {
        return
      }
      const hasProducts = this.products && this.products.length
      const hasItems = hasProducts && this.products[0].items && this.products[0].items.length
      if (hasItems) {
        const product = this.products[0]
        const item = this.products[0].items[0]
        const arr = [`${product.id}_${item.id}`]
        this.openedProductsDropdown.setArray(arr)
        return product
      }
    }
  }
};
